<template>
    <section class="content-register">
      <div class="content-register-itens container">
        <router-link tag="a" :to="{name: 'login'}" class="content-register-itens--logo">
          <img class="img-fluid" src="@/assets/img/logo-grupo-nabeiro.png" alt="logo grupo nabeiro" title="logo grupo nabeiro"/>
        </router-link>
        <form ref="formRegister" @submit.prevent="onRegister" class="content-register-itens-form">
          <div class="content-register-itens-form--title">
            <h1>Crear cuenta</h1>
          </div>
          <div class="content-register-itens-form-inputs">
            <input type="hidden" name="form_origin" value="create_account_two">
            <input type="hidden" name="code" :value="code">
            <div class="row">
              <input type="hidden" name="code" :value="code">
              <div class="col-lg-8">
                <div class="content-input">
                  <input type="email" name="email" :value="user.email" placeholder="E-mail" required/>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="content-input">
                  <input type="date" name="birthday" :value="user.birthday" required/>
                  <label>* Fecha de nacimiento</label>
                </div>
              </div>
            </div>
          </div>
          <div class="content-register-itens-form-btns">
            <button type="submit" class="btn--black">
              <p>Confirmar</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
            </button>
            <router-link class="btn--link" tag="a" :to="{name: 'login'}">Volver</router-link>
          </div>
        </form>
      </div>
      <ModalCollaborator/>
    </section>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import axios from 'axios';
  
  export default {
    data() {
      return {
        code: localStorage.getItem('code') || null,
      };
    },
    computed: {
      ...mapGetters('User', ['user']),
    },
    methods:{
      ...mapActions('App', ['loading']),    
      ...mapActions('App', ['loading']),
      ...mapActions('User', ['setUser', 'setToken']),    
      async onRegister(el){            
          let formData = new FormData(this.$refs.formRegister)
          this.loading(true);
          await axios({
              method: 'POST',
              url: '/api/v1/campaign/user/register',
              data: formData
          }).then(response => response.data)
          .then((data) => {                
              this.loading(false);
              this.$notify({                    
                  type: "success",
                  text: data.message,
                  duration: 600
              });
              localStorage.setItem('code', data.data.code);
              this.code = localStorage.getItem('code');
              this.setUser(data.data);
              this.setToken(data.data.token);
              this.$router.push({
                name: 'home'
              })
          })
          .catch((error) => {
              this.loading(false);
              this.$notify({                    
                  type: "warn",
                  text: error.response.data.message,
                  duration: 600
              });
          })
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import './index.scss';
  .btn--help {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: 0;
    border: 0;
    p {
      font-size: 8px;
      font-weight: var(--font-weight-regular);
      color: var(--black);
      text-transform: uppercase;
      margin-right: 5px;
      margin-bottom: 0;
    }
  }
  </style>
  