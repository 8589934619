<template>
  <section class="content-my-data">
    <div class="content-my-data-itens container">
      <router-link tag="a" :to="{name: 'home'}" class="content-my-data-itens--logo">
        <img class="img-fluid" src="@/assets/img/logo-grupo-nabeiro.png" alt="logo grupo nabeiro" title="logo grupo nabeiro"/>
      </router-link>
      <form class="content-my-data-itens-form">
        <div class="content-my-data-itens-form--title">
          <h1>Tu información</h1>
        </div>
        <div class="content-my-data-itens-form-inputs">
          <div class="row">
            <div class="col-lg-8">
              <div class="content-input">
                <input type="email" name="email" value="" placeholder="E-mail" required/>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="content-input">
                <input type="date" name="birthday" value="" placeholder="Fecha de nacimiento" required/>
              </div>
            </div>
          </div>
        </div>
        <div class="recaptcha">
        </div>
        <div class="content-my-data-itens-form-btns">
          <button class="btn--black">
            <p>Confirmar</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
          </button>
          <router-link class="btn--link" tag="a" :to="{name: 'register'}">Volver</router-link>
        </div>
      </form>
    </div>
  </section>
</template>

<script>

export default {
}
</script>

<style lang="scss">
@import './index.scss';
</style>
