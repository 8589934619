<template>
  <section class="content-register">
    <div class="content-register-itens container">
      <router-link tag="a" :to="{name: 'login'}" class="content-register-itens--logo">
        <img class="img-fluid" src="@/assets/img/logo-grupo-nabeiro.png" alt="logo grupo nabeiro" title="logo grupo nabeiro"/>
      </router-link>
      <form ref="formRegister" @submit.prevent="onRegister" class="content-register-itens-form">
        <div class="content-register-itens-form--title">
          <h1>Crear cuenta</h1>
        </div>
        <div class="content-register-itens-form-inputs">
          <div class="row">
            <input type="hidden" name="form_origin" value="create_account_one">
            <!-- hidden input owner_id 3 == DELTA ESPANHA -->
            <input type="hidden" value="3" name="owner_id" />
            <div class="col-lg-6">
              <div class="content-input">
                <input type="text" name="code" value="" placeholder="ID de empleado"/>
                <span v-if="errors.code" v-bind:class="{ 'has-error': errors.code }">{{ errors.code }}</span>
                <button type="button" class="btn--help" @click="$bvModal.show('modal-collaborator')">
                  <p>Ayuda</p>
                  <img class="img-fluid" src="@/assets/img/icon-help.png" alt="icon help" title="icon help"/>
                </button>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="content-input">
                <input type="text" name="name" value="" placeholder="Nombre"/>
                <span v-if="errors.name" v-bind:class="{ 'has-error': errors.name }">{{ errors.name }}</span>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="content-input">
                <input type="text" name="nickname" value="" placeholder="Apellido"/>
                <span v-if="errors.nickname" v-bind:class="{ 'has-error': errors.nickname }">{{ errors.nickname }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="content-input">
                <input type="password" name="password" value="" placeholder="Contraseña"/>
                <span v-if="errors.password" v-bind:class="{ 'has-error': errors.password }">{{ errors.password }}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-input">
                <input type="password" name="password_confirmation" value="" placeholder="Confirmar contraseña"/>
                <span v-if="errors.password_confirmation" v-bind:class="{ 'has-error': errors.password_confirmation }">{{ errors.password_confirmation }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <template>
              <div class="col-12">
                <div class="content-input-check">
                  <input type="checkbox" name="accepted_terms" value="1"/>
                  <label>Declaro que he leído y acepto los <span>
                    <a href="/storage/app/media/DeltaES/regulamento_movimiento_clientes_amigos_ESP.pdf" target="_blank" rel="noopener">Términos y Condiciones</a>
                  </span> de participación y he leído y entendido el 
                  <a href="/storage/app/media/DeltaES/política_privacidad_clientes_amigos.pdf" target="_blank" rel="noopener">Política de Privacidad</a></label>
                </div>
                <div class="content-input-check">
                  <span v-if="errors.accepted_terms" v-bind:class="{ 'has-error': errors.accepted_terms }">{{ errors.accepted_terms }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="recaptcha">
          <div class="g-recaptcha" data-sitekey="6LdJIP8mAAAAAEEMHrBnMb9oUTgwGGkDogSXbyut"></div>
        </div>
        <div class="content-register-itens-form-btns">
          <button type="submit" class="btn--black">
            <p>Crea una cuenta</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
          </button>
          <router-link class="btn--link" tag="a" :to="{name: 'login'}">Volver</router-link>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="content-register-itens-form--footer">
              <p>Si tiene dificultades para registrarse, envíe un correo electrónico a:
	              <br><a href="mailto:clientesamigos@gruponabeiro.com" target="_blank">clientesamigos@gruponabeiro.<wbr>com</a>.</p>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ModalCollaborator/>
  </section>
</template>

<script>
import ModalCollaborator from '@/components/Modals/ModalCollaborator';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      code: '',
      errors: {
        code: '',
        name: '',
        nickname: '',
        password: '',
        password_confirmation: '',
        accepted_terms: ''
      }
    };
  },
  components: {
    ModalCollaborator
  },
  computed: {
    ...mapGetters('App', ['recaptchaKey']),
    ...mapGetters('User', ['user']),
  },
  methods:{
    ...mapActions('App', ['loading']),    
    ...mapActions('App', ['loading']),
    ...mapActions('User', ['setUser', 'setToken']),    
    
    async onRegister(el){   
        this.formValidate();

        if (this.errors.code || this.errors.name || this.errors.nickname || this.errors.password || this.errors.password_confirmation || this.errors.accepted_terms) {
          return;
        }         
        let formData = new FormData(this.$refs.formRegister)
        this.loading(true);
        await axios({
            method: 'POST',
            url: '/api/v1/campaign/user/register',
            data: formData
        }).then(response => response.data)
        .then((data) => {                
            this.loading(false);
            this.$notify({                    
                type: "success",
                text: data.message,
                duration: 600
            });
            localStorage.setItem('code', data.data.code);
            this.code = localStorage.getItem('code');
            this.setUser(data.data);
            this.$router.push({
              name: 'create-account'
            })
        })
        .catch((error) => {
            this.loading(false);
            this.$notify({                    
                type: "warn",
                text: error.response.data.message,
                duration: 3000,
            });
        })
    },

    formValidate() {
      this.validateCode();
      this.validateName();
      this.validateNickname();
      this.validatePassword();
      this.validatePasswordConfirmation();
      this.validateAcceptedTerms();
    },

    validateCode() {
      if (!this.$refs.formRegister.code.value) {
        this.errors.code = 'El campo ID de empleado es obligatorio';
      } else {
        this.errors.code = '';
      }
    },

    validateName() {
      if (!this.$refs.formRegister.name.value) {
        this.errors.name = 'El campo Nombre es obligatorio';
      } else {
        this.errors.name = '';
      }
    },

    validateNickname() {
      if (!this.$refs.formRegister.nickname.value) {
        this.errors.nickname = 'El campo Apellido es obligatorio';
      } else {
        this.errors.nickname = '';
      }
    },

    validatePassword() {
      if (!this.$refs.formRegister.password.value) {
        this.errors.password = 'El campo Contraseña es obligatorio';
      } else {
        this.errors.password = '';
      }
    },

    validatePasswordConfirmation() {
      if (!this.$refs.formRegister.password_confirmation.value) {
        this.errors.password_confirmation = 'El campo Confirmar contraseña es obligatorio';
      } else {
        this.errors.password_confirmation = '';
      }
    },
    validateAcceptedTerms() {
      if (!this.$refs.formRegister.accepted_terms.checked) {
        this.errors.accepted_terms = 'El campo Declaro que he leído y acepto los Términos y Condiciones de participación y he leído y entendido el Política de Privacidad es obligatorio';
      } else {
        this.errors.accepted_terms = '';
      }
    },
  },

  watch: {
    code: function (code) {
      this.codeValid = this.validateEmail(code);
    },
    name: function (name) {
      this.nameValid = this.validateName(name);
    },
    nickname: function (nickname) {
      this.nicknameValid = this.validateNickname(nickname);
    },
    password: function (password) {
      this.passwordValid = this.validatePassword(password);
    },
    password_confirmation: function (password_confirmation) {
      this.password_confirmationValid = this.validatePasswordConfirmation(password_confirmation);
    },
    accepted_terms: function (accepted_terms) {
      this.accepted_termsValid = this.validateAcceptedTerms(accepted_terms);
    }
      
  }
}
</script>

<style lang="scss">
@import './index.scss';
.btn--help {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: 0;
  border: 0;
  p {
    font-size: 8px;
    font-weight: var(--font-weight-regular);
    color: var(--black);
    text-transform: uppercase;
    margin-right: 5px;
    margin-bottom: 0;
  }
}
</style>
